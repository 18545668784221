import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';
import React from 'react';

const HeadingText = styled(Typography)`
    color: #FFFFFF;
    font-family: ${props => props.fm ? props.fm : "GothicR"};
    /* text-align: ${props=>props.ta? props.ta : ''}; */
    text-align: center;
`
export const Heading = (props) => {
    const { title, pd } = props;
    return(
        <Grid  item  xs={12} padding={pd} {...props}>
            <HeadingText variant='h2'>{title}</HeadingText>
        </Grid>
    )
}
