import { Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import { Text } from "../styling/style";
import { Heading } from "../components/heading";

export const About = ({ sectionId, inView, onChange, children, refProp }) => {
  const ref = useRef();
  return (
    <Container
      id="about"
      maxWidth="xl"
      ref={refProp || ref}
      sx={{
        minHeight: "calc(105vh)",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        pt: "50px",
      }}
    >
      {inView && onChange(sectionId, inView)}
      <Grid container>
        {/* <Grid xs={12}>
                    <AllImage src={AboutImage}/>
                </Grid> */}
        {/* <Grid xs={12}> */}
        <Heading
          pd="2% 0% 3%"
          title="Le Panthéon Céleste Nostradamien"
          data-aos="fade-up"
          data-aos-duration="1000"
        />
        {/* </Grid> */}
        <Grid item xs={12} data-aos="fade-up" data-aos-duration="1300">
          <Text mw="650px" fs="18px" fs600="14px">
            Step into excellence with "Le Panthéon Céleste Nostradamien,"
            Nostradamus' Wall of Fame. This dynamic leaderboard celebrates the
            best-performing traders using the Nostra bot, showcasing their
            remarkable success in cryptocurrency margin trading.
          </Text>
          <Text mw="670px" fs="18px" fs600="14px" p="2% 0%">
            From seasoned professionals to enthusiastic newcomers, this
            real-time update fosters a spirit of healthy competition and
            inspiration for new strategies within the Nostradamus community.
            Join the elite ranks, etch your name into the celestial pantheon,
            and let Nostradamus be your guide to trading greatness.
          </Text>
        </Grid>
      </Grid>
    </Container>
  );
};
