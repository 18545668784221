import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

const AllImage = styled.img`
  width: 100%;
  height: auto;
  max-width: ${(props) => (props.mw ? props.mw : "")};
  max-width: ${(props) => (props.maxw ? props.maxw : "")};

`;
const Ulist = styled.ul`
  display: flex;
  align-items: center;
  @media (max-width: 860px) {
    display: none;
  }
`;
const ListStyle = styled.li`
  list-style: none;
  padding: 0px 15px;
`;
const ListItems = styled.a`
  text-decoration: none;
  color: #ac412a;
  font-family: GothicR;
  :hover {
    color: #ffffff;
  }
`;
const Text = styled(Typography)`
  color: ${(props) => (props.cl ? props.cl : "#FFFFFF")};
  font-size: ${(props) => (props.fs ? props.fs : "")};
  text-align: ${(props) => (props.ta ? props.ta : "center")};
  max-width: ${(props) => (props.mw ? props.mw : "")};
  padding: ${(props) => (props.p ? props.p : "")};
  margin: auto;
  font-family: ${(props) => (props.fm ? props.fm : "GothicR")};
  @media (max-width: 600px) {
    font-size: ${(props) => (props.fs600 ? props.fs600 : "")};
  }
`;
const Listdrawer = styled.a`
  color: white;
  text-decoration: none;
  font-size: 18px;
  margin: 1rem 0;
  padding: 8px 12px;
  font-family: poppins-semibold;
`;
const NavBtn = styled.div`
  padding: 0px;
  display: none;
  cursor: pointer;
  font-size: 1.8rem;
  color: white;
  @media (max-width: 850px) {
    display: block;
  }
`;
const Menustyling = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
`;
const IconStyle = styled.a`
  background-color: white;
  padding: ${(props) => (props.p ? props.p : "10px 10px 5px")};
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
`;
const LinkStyle = styled(Link)`
  text-decoration: none;
  color: white;
`;
const Liststyling = styled.li`
padding: 10px 12px;
display: flex;
list-style: none;
display: block;
text-align: left;
padding: ${props => props.pd? props.pd : '10px 10px'};
`;
export { Ulist, ListStyle, ListItems, AllImage, Text,IconStyle,LinkStyle,Listdrawer,Menustyling,NavBtn,Liststyling };
