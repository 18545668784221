import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import styled from '@emotion/styled';
import TabCard from './tabscards';
import { Container, Grid, IconButton, Modal } from '@mui/material';
import Bit from '../assets/bitlogo.png';
import Eth from '../assets/ethlogo.png';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import CancelIcon from '@mui/icons-material/Cancel';
import TAbsIcon from '../assets/communittabsicon.png';
import Background from '../assets/header-background.png';
import html2canvas from 'html2canvas';
import CopyIcon from '../assets/copy icon.png';
import ImageIcon from '../assets/image-icon.png';
import XPIcon from '../assets/x-popupicon.png';
import TPIcon from '../assets/telegram=popup.png';
import { AllImage } from '../styling/style';
import Logo from '../assets/logo.png';
import clsx from 'clsx';
import Popup from './popup';
import clipboardCopy from 'clipboard-copy';

const TabStyle = styled(Tab)`
    color: #FFFFFF;
    border-bottom: none;
    &.Mui-selected{
        color: white;
        border-bottom: 2px solid #F3695D;
    }
`
const CardDetails = styled(Typography)`
    color: #FFFFFF;
    font-family: GothicR;
    text-align: ${props => props.ta? props.ta : 'center'};
    @media (max-width: 600px){
        text-align: center;
    }
`
const DetailGrid = styled( Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 0px;
    @media (max-width: 750px){
        flex-direction: column;
    }
`
const CardTag = styled(Typography)`
   color: #707070;
    font-family: GothicR;
    text-align: ${props => props.ta? props.ta : 'center'};
    @media (max-width: 600px){
        text-align: left;
    }
`
const CardGrid = styled.div`
  background: linear-gradient( to top, #000000, #1C1C1C );
  border-radius: 20px;
  text-align: center;
  padding: 2% 5% 4%;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styled.div`
  width: 100%;
  max-width: 50%;
  height: auto;
  margin: 20px;
  @media (min-width: 700px) and (max-width: 1300px){
    max-width: 80%;
  }
  @media (max-width: 700px){
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 5% 0px;
  }
`;
const Tag = styled(Typography)`
  color: #FFFFFF;
  font-family: GothicR;
  font-size: 14px;
    @media (max-width: 600px){
       font-size: 11px;
  }
`;

const PopupGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 5% 0% 0%;
  justify-content: center;
  align-items: center;
`;
const StyledBackdrop = styled.div`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const BackGrid = styled(Grid)`
   /* background-image: url(${Background});
   background-size: 100% 100%;
   background-position: center;
   background-repeat: no-repeat; */
   max-width: 100%;
`
const Tag1 = styled(Typography)`
    color: white;
    font-family: poppins-bold;
    text-align: left;
    padding: ${props => props.p? props.p : ''};
    &.span{
        color: #37FF58;
    }
`
const Details = styled(Typography)`
    color: white;
    font-family: GothicR;
    font-size: 12px;
    text-align: left;
    line-height: 2;
    padding: ${props => props.p? props.p : ''};
`
const Name = styled(Typography)`
    color: white;
    font-family: poppins-semibold;
    padding-left: 3%;
    text-align: center;
    font-size: 17px;
     @media (max-width: 900px){
       font-size: 12px;
       padding-left: 30px;
  }
`
const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};
const ComTabs = styled(Tabs)`
  .MuiTabs-flexContainer{
    border-bottom: 1px solid #707070;
  }
`
const DetailBox = styled(Box)`
   display: flex;
   justify-content: space-between;
   align-items: center;
   @media (max-width: 750px){
        flex-direction: column;
    }
`
const ImageSrc = styled(AllImage)`
  max-width: 200px;
  padding-left: 18%;
  @media (max-width: 600px){
    padding-left: 10%;
  }
  @media (min-width: 1800px){
    padding-left: 28%;
  }
`
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const { symbol, buy, buysell, leverage, pnl, imageSrc, avgCost, closePrice, name} = props ;
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveImage = () => {
    html2canvas(document.querySelector('#popup-content')).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'popup-image.png';
      link.click();
    });
  };

  const copyImageToClipboard = () => {
    const imageSrc = props.imageSrc;

    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';

    input.value = imageSrc;

    document.body.appendChild(input);

    input.select();

    clipboardCopy(input.value).then(() => {
      setIsCopied(true);
    });

    document.body.removeChild(input);
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <Container Maxwidth='lg'>
      <Grid container>
    <Grid xs={12} sx={{padding: '1% 0 0%'}}>
    <Box sx={{ width: '100%'}}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <ComTabs value={value} onChange={handleChange} TabIndicatorProps={{
          
            style: {
              backgroundColor: '#F3695D',
            },
          }}>
          <TabStyle label="Ongoing Orders" {...a11yProps(0)} />
          <TabStyle label="History" {...a11yProps(1)} />
        </ComTabs>
      </Box>
      <CustomTabPanel value={value} index={0} >
        <Grid xs={12}>
            <TabCard image={Bit} date = '09/20/2023, 16:09:36'
             popup = {<Popup {...props.ongoingCard1}/>}>
              <DetailGrid xs={12} md={12}>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Position Size</CardTag>
                    <CardDetails>0.13 BTC</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Mark Price</CardTag>
                    <CardDetails>27,097.2 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Pnl</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>1.75 USDT</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
              <DetailGrid xs={12} md={12}>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Margin</CardTag>
                    <CardDetails>176.04 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Entry Price</CardTag>
                    <CardDetails>27,097.2 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Pnl%</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>0.99%</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
            </TabCard>
        </Grid>
        <Grid xs={12}>
          <TabCard image={Eth} date = '09/20/2023, 16:09:36'
            popup = {<Popup {...props.ongoingCard2}/>}
            >
            <DetailGrid xs={12} md={12}>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Position Size</CardTag>
                    <CardDetails>0.33 ETH</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Mark Price</CardTag>
                    <CardDetails>1,647.2 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Pnl</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>55.75 USDT</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
              <DetailGrid xs={12} md={12}>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Margin</CardTag>
                    <CardDetails>176.04 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Entry Price</CardTag>
                    <CardDetails>1,620 USDT</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} sm={6} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Pnl%</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>4.99%</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
            </TabCard>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} >
      <Grid xs={12}>
            <TabCard image={Bit}
            popup={ 
              <div>
              <Grid xs={12} sx={{textAlign: 'center', display: 'flex', justifyContent: 'center'}}>
                <IconButton onClick={handleOpen}>
                  <AllImage src={TAbsIcon}/>
                </IconButton>
              </Grid>
              <StyledModal
                aria-labelledby="unstyled-modal-title"
                aria-describedby="unstyled-modal-description"
                open={open}
              >
                <ModalContent id="popup-content">
                  <CardGrid>
                    <Grid xs={12} sx={{display: 'flex' , justifyContent: 'flex-end', padding:'0% 0% 5px'}}>
                      <IconButton onClick={handleClose} sx={{padding: '8px 0px'}}><CancelIcon sx={{color: 'white'}} /></IconButton>
                    </Grid>
                    <BackGrid container>
                      <Grid xs={12} sm={6} md={7} p={2}>
                        <Grid xs={12} sx={{display: 'flex'}}>
                            <AllImage maxw='150px' src={Logo}/>
                        </Grid>
                        <Tag1 variant="h5" p='10px 0px'>{symbol}</Tag1>
                        <Box sx={{display: 'flex'}}>
                          <Details><span style={{color: '#37FF58', borderRight: '1px solid white'}}>{buy}&nbsp;&nbsp;</span></Details>
                          <Details>&nbsp; &nbsp;{buysell}</Details>
                        </Box>
                        <Grid xs={12}>
                          <Details p='20px 0px 5px'>{pnl}</Details>
                    </Grid>
                    <Grid xs={12}>
                        <Tag1 variant="h3" p ='20px 0px'><span style={{color: '#37FF58'}}>{leverage}</span></Tag1>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Details>Avg.Cost<br/>{avgCost}</Details>
                            <Details>close Price<br/>{closePrice}</Details>
                        </Box>
                    </Grid>
                    <Grid xs={12}>
                        <Details p='20px 0px'>www.nostrabot.com</Details>
                    </Grid>
                    </Grid>
                    <Grid xs={7} sm={6} md={5} 
                    sx={{ display: 'flex', justifyContent: 'flex-end', margin: 'auto', flexDirection: 'column' }}
                    >
                        <ImageSrc src={imageSrc}/>
                        <Name variant='h5'>{name}</Name>
                    </Grid>
                    </BackGrid>
                    <Grid container spacing={1} justifyContent='center'>
                      <PopupGrid xs={6} md={3}>
                        <a href='#'  onClick={copyImageToClipboard}><AllImage maxw='40px' src={CopyIcon}/></a>
                        <Tag variant='subtitle-1'>Copy Link</Tag>
                      </PopupGrid>
                      <PopupGrid xs={6} md={3}>
                        <a href='#'  onClick={handleSaveImage}><AllImage maxw='40px' src={ImageIcon}/></a>
                        <Tag variant='subtitle-1'>Save Image</Tag>
                      </PopupGrid>
                      <PopupGrid xs={6} md={3}>
                        <a href='#'><AllImage maxw='40px' src={XPIcon}/></a>
                        <Tag variant='subtitle-1'>X</Tag>
                      </PopupGrid>
                      <PopupGrid xs={6} md={3}>
                        <a href='#'><AllImage maxw='40px' src={TPIcon}/></a>
                        <Tag variant='subtitle-1'>Telegram</Tag>
                      </PopupGrid>
                    </Grid>
                  </CardGrid>
                </ModalContent>
              </StyledModal>
            </div>}>
            <DetailGrid xs={12} md={12}>
                <Grid xs={12} md={3} p={1}>
                  <DetailBox>
                    <CardTag>Opened</CardTag>
                    <CardDetails>09/20/2023, 14:53:06</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Avg. Cost</CardTag>
                    <CardDetails>{avgCost}</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Pnl</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>10.68 USDT</span></CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Position Size</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>0.13 BTC</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
              <DetailGrid xs={12} md={12}>
                <Grid xs={12} md={3} p={0}>
                  <DetailBox>
                    <CardTag>All Closed</CardTag>
                    <CardDetails>09/20/2023, 16:08:30</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Fill Price</CardTag>
                    <CardDetails>{closePrice}</CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Pnl%</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>{leverage}</span></CardDetails>
                  </DetailBox>
                </Grid>
                <Grid xs={12} md={3} p={2}>
                  <DetailBox>
                    <CardTag>Margin</CardTag>
                    <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58' }}>175.55 USDT</span></CardDetails>
                  </DetailBox>
                </Grid>
              </DetailGrid>
            </TabCard>
        </Grid>
      </CustomTabPanel>
    </Box>
    </Grid>
    </Grid>
    </Container>
  );
}