import styled from '@emotion/styled';
import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import WallOfFameCards from '../components/walloffamecards';
import FavouriteIcon from '@mui/icons-material/StarBorderOutlined';
import Card1 from '../assets/card1.png';
import Card2 from '../assets/card2.png';
import Card3 from '../assets/card3.png';
import Card4 from '../assets/card4.png';
import Card5 from '../assets/card5.png';
import Card6 from '../assets/card6.png';
import StarIcon from '@mui/icons-material/Star';
import { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";

const CommunityTitle = styled(Typography)`
    color: #FFFFFF;
    font-family: poppins-semibold;
`
const CardDetails = styled(Typography)`
    color: #FFFFFF;
    font-family: GothicR;
    text-align: center;
    @media (max-width: 600px){
        text-align: left;
    }
`
const HeadingGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5% 0 1%;
    font-size: ${props=>props.font? props.font : '16px'};
    @media (max-width: 600px){
        flex-direction: column;
        display: contents;
    }
`

const CommunityTraders = () =>{
    // const [selectedTrader, setSelectedTrader] = useState(null);
    const [favoriteTraders, setFavoriteTraders] = useState([]);
    const [showFavorites, setShowFavorites] = useState(false);

    const cardData = [
        {
            id: '@cryptonoan',
            image: Card1,
            address:  '0Xxxxxxx00000000000000000',
            buttontag:  'View Detail',
            investment:  '$1,500',
            roi:  '+250%',
            winrate:  '60%',
            totaltrades:  '4',
            returnamount:  '$2,250',
            position:  'Open',
            isFavorite: favoriteTraders.includes('@cryptonoan'),
        },
        {
            image:  Card2,
             id:  '@drbuny',
            address:  '0Xxxxxxx00000000000000000',
            buttontag:  'View Detail',
            investment:  '$1,500',
            roi:  '+250%',
            winrate:  '60%',
            totaltrades:  '4',
            returnamount:  '$2,250',
            position:  'Open'
        },
        {
            image : Card3,
            id: '@overdose_gems_group',
            address : '0Xxxxxxx00000000000000000',
            buttontag : 'View Detail',
            investment : '$1,500',
            roi : '+271.5%',
            winrate : '75%',
            totaltrades : '8',
            returnamount : '$3,710',
            position : 'Open',
            isFavorite: favoriteTraders.includes('@overdose_gems_group'),
        },
        {
            image : Card4,
            id: '@spicygems',
            address : '0Xxxxxxx00000000000000000',
            buttontag : 'View Detail',
            investment : '$1,500',
            roi : '+250%',
            winrate : '60%',
            totaltrades : '4',
            returnamount : '$2,250',
            position : 'Open',
        },
        {
            image : Card5,
            id: '@crazylandinhabitant',
            address : '0Xxxxxxx00000000000000000',
            buttontag : 'View Detail',
            investment : '$1,500',
            roi : '+271.5%',
            winrate : '75%',
            totaltrades : '8',
            returnamount : '$3,710',
            position : 'Open',
        },
        {
            image : Card6,
            id: '@ricardo',
            address : '0Xxxxxxx00000000000000000',
            buttontag : 'View Detail',
            investment : '$1,500',
            roi : '+250%',
            winrate : '60%',
            totaltrades : '4',
            returnamount : '$2,250',
            position : 'Open',
            isFavorite: favoriteTraders.includes('@ricardo'),
        },
    ];

    useEffect(() => {
        AOS.init({
            duration: 800,
            offset: 200,
        });
    }, []);

    useEffect(() => {
        AOS.refresh();
    });

    const toggleAllFavorites = () => {
        if (showFavorites) {

            cardData.forEach((card) => {
                card.isFavorite = false;
            });
            setFavoriteTraders([]);
        } else {

            cardData.forEach((card) => {
                card.isFavorite = true;
            });
            setFavoriteTraders(cardData.map((card) => card.id));
        }
        setShowFavorites(!showFavorites);
    };
    const filteredCardData = showFavorites
    ? cardData.filter((card) => card.isFavorite)
    : cardData;

    return(
        <Container maxWidth='lg' sx={{maxHeight:"calc(100vh - 332px)",overflow:"auto",paddingTop:"70px"}}>
            <Grid container >
                <HeadingGrid xs={12} data-aos='fade-up'>
                    <CommunityTitle variant='h4'>Community Traders</CommunityTitle>
                    <Box  sx={{display: 'flex', justifyContent: 'space-between'}}>
                    <FavouriteIcon
                            sx={{
                                color: showFavorites ? 'yellow' : '#FFFFFF',
                                cursor: 'pointer',
                            }}
                            onClick={toggleAllFavorites}
                        />
                        <CardDetails font='18px'>&nbsp; Favourite</CardDetails>
                    </Box>
                </HeadingGrid>
                <Grid container >
                {filteredCardData.map((card, index) => (
                        <Grid xs={12} md={4} p={1} key={index}>
                            <WallOfFameCards
                                image={card.image}
                                id={card.id}
                                address={card.address}
                                buttontag={card.buttontag}
                                // onItemClick={() => handleItemClick('')}
                                investment={card.investment}
                                roi={card.roi}
                                winrate={card.winrate}
                                totaltrades={card.totaltrades}
                                returnamount={card.returnamount}
                                position={card.position}
                                favorite={card.isFavorite}
                                onFavoriteClick={() => {
                                    card.isFavorite = !card.isFavorite;
                  
                                    if (card.isFavorite) {
                                      setFavoriteTraders([...favoriteTraders, card.id]);
                                    } else {
                                      setFavoriteTraders(favoriteTraders.filter((trader) => trader !== card.id));
                                    }
                                  }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Container>
    )
}

export default CommunityTraders;