import * as React from 'react';
import PropTypes from 'prop-types';
import Logo from '../assets/logo.png';
import clsx from 'clsx';
import { Modal, Grid, Typography, IconButton, Box } from '@mui/material';
import { AllImage } from '../styling/style';
import CopyIcon from '../assets/copy icon.png';
import ImageIcon from '../assets/image-icon.png';
import XPIcon from '../assets/x-popupicon.png';
import TPIcon from '../assets/telegram=popup.png';
import styled from '@emotion/styled';
import CancelIcon from '@mui/icons-material/Cancel';
import TAbsIcon from '../assets/communittabsicon.png';
import Background from '../assets/header-background.png';
import html2canvas from 'html2canvas';
import clipboardCopy from 'clipboard-copy';



const CardGrid = styled.div`
  background: linear-gradient( to top, #000000, #1C1C1C );
  border-radius: 20px;
  text-align: center;
  padding: 2% 5% 4%;
`;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ModalContent = styled.div`
  width: 100%;
  max-width: 50%;
  height: auto;
  margin: 20px;
  @media (min-width: 700px) and (max-width: 900px){
    max-width: 40%;
  }
  @media (max-width: 700px){
    max-width: 100%;
    height: 100%;
    overflow-y: auto;
    margin: 5% 0px;
  }
`;
const Tag = styled(Typography)`
  color: #FFFFFF;
  font-family: GothicR;
  font-size: 14px;
    @media (max-width: 600px){
       font-size: 11px;
  }
`;

const PopupGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding: 5% 0% 0%;
  justify-content: center;
  align-items: center;
`;
const StyledBackdrop = styled.div`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const BackGrid = styled(Grid)`
   /* background-image: url(${Background});
   background-size: 100% 100%;
   background-position: center;
   background-repeat: no-repeat; */
   max-width: 100%;
`
const Tag1 = styled(Typography)`
    color: white;
    font-family: poppins-bold;
    text-align: left;
    padding: ${props => props.p? props.p : ''};
    &.span{
        color: #37FF58;
    }
`
const Details = styled(Typography)`
    color: white;
    font-family: GothicR;
    font-size: 12px;
    text-align: left;
    line-height: 2;
    padding: ${props => props.p? props.p : ''};
`
const Name = styled(Typography)`
    color: white;
    font-family: poppins-semibold;
    padding-left: 3%;
    text-align: center;
    font-size: 17px;
    @media (min-width: 400px) and (max-width: 600px){
       font-size: 12px;
       padding-left: 30px;
  }
    @media (max-width: 400px){
      padding-left: 0%;
      font-size: 12px;
  }
     @media (min-width: 600px) and (max-width: 900px){
       font-size: 12px;
       padding-left: 30px;
  }
`
const ImageSrc = styled(AllImage)`
  max-width: 200px;
  padding-left: 18%;
  @media (max-width: 499px){
    padding-left: 0%;
  }
  @media (min-width: 400px) and (max-width: 500px){
    padding-left: 15%;
  }
  @media (min-width: 500px) and (max-width: 600px){
    padding-left: 20%;
  }
  @media (min-width: 1800px){
    padding-left: 28%;
  }
`
const Backdrop = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ 'MuiBackdrop-open': open }, className)}
      ref={ref}
      {...other}
    />
  );
});

Backdrop.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default function Popup(props) {
  const [open, setOpen] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveImage = () => {
    html2canvas(document.querySelector('#popup-content')).then((canvas) => {
      const link = document.createElement('a');
      link.href = canvas.toDataURL();
      link.download = 'popup-image.png';
      link.click();
    });
  };

  const copyImageToClipboard = () => {
    const imageSrc = props.imageSrc;

    const input = document.createElement('input');
    input.style.position = 'fixed';
    input.style.opacity = '0';

    input.value = imageSrc;

    document.body.appendChild(input);

    input.select();

    clipboardCopy(input.value).then(() => {
      setIsCopied(true);
    });

    document.body.removeChild(input);
  };

  const { symbol, buy, buysell, leverage, pnl, imageSrc, avgCost, closePrice, name } = props;

  return (
    <div>
      <Grid xs={12} sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
        <IconButton onClick={handleOpen}>
          <AllImage src={TAbsIcon} />
        </IconButton>
      </Grid>
      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
      >
        <ModalContent id="popup-content">
          <CardGrid>
            <Grid xs={12} sx={{ display: 'flex', justifyContent: 'flex-end', padding: '0% 0% 5px' }}>
              <IconButton onClick={handleClose} sx={{ padding: '8px 0px' }}><CancelIcon sx={{ color: 'white' }} /></IconButton>
            </Grid>
            <BackGrid container>
              <Grid xs={12} sm={6} md={7} p={2}>
                <Grid xs={12} sx={{ display: 'flex' }}>
                  <AllImage maxw='150px' src={Logo} />
                </Grid>
                <Tag1 variant="h5" p='10px 0px'>{symbol}</Tag1>
                <Box sx={{ display: 'flex' }}>
                  <Details><span style={{ color: '#37FF58', borderRight: '1px solid white' }}>{buy}&nbsp;&nbsp;</span></Details>
                  <Details>&nbsp; &nbsp;{buysell}</Details>
                </Box>
                <Grid xs={12}>
                  <Details p='20px 0px 5px'>{pnl}</Details>
                </Grid>
                <Grid xs={12}>
                  <Tag1 variant="h3" p='20px 0px'><span style={{ color: '#37FF58' }}>{leverage}</span></Tag1>
                </Grid>
                <Grid xs={12} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Details>Avg.Cost<br />{avgCost}</Details>
                    <Details>close Price<br />{closePrice}</Details>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <Details p='20px 0px'>www.nostrabot.com</Details>
                </Grid>
              </Grid>
              <Grid xs={7} sm={6} md={5}
                sx={{ display: 'flex', justifyContent: 'flex-end', margin: 'auto', flexDirection: 'column' }}
              >
                <ImageSrc src={imageSrc} />
                <Name variant='h5'>{name}</Name>
              </Grid>
            </BackGrid>
            <Grid container spacing={1} justifyContent='center'>
              <PopupGrid xs={6} md={3}>
                <a href='#' onClick={copyImageToClipboard}>
                  <AllImage maxw='40px' src={CopyIcon} />
                </a>
                <Tag variant='subtitle-1'>{isCopied ? 'Copied' : 'Copy Link'}</Tag>
              </PopupGrid>
              <PopupGrid xs={6} md={3}>
                <a href='#' onClick={handleSaveImage}><AllImage maxw='40px' src={ImageIcon} /></a>
                <Tag variant='subtitle-1'>Save Image</Tag>
              </PopupGrid>
              <PopupGrid xs={6} md={3}>
                <a href='#'><AllImage maxw='40px' src={XPIcon} /></a>
                <Tag variant='subtitle-1'>X</Tag>
              </PopupGrid>
              <PopupGrid xs={6} md={3}>
                <a href='#'><AllImage maxw='40px' src={TPIcon} /></a>
                <Tag variant='subtitle-1'>Telegram</Tag>
              </PopupGrid>
            </Grid>
          </CardGrid>
        </ModalContent>
      </StyledModal>
    </div>
  );
}

