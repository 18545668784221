import { Container, Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import bg from "../assets/bg.mp4";
import last from "../assets/last.png";
import nostro1 from "../assets/audios/nostro1.mp3";
import nostro2 from "../assets/audios/nostro2.mp3";
import nostro3 from "../assets/audios/nostro3.mp3";
import nostro4 from "../assets/audios/nostro4.mp3";
import nostro5 from "../assets/audios/nostro5.mp3";
import { AllImage } from "../styling/style";
import styled from "@emotion/styled";
import { CustomButton } from "../components/button";
import { Header } from "./1-header";
import { Leverage } from "./2-leverge";
import { About } from "./3-about";
import { Feature } from "./4-features";
import Partner from "./7-partner";
import { Alert } from "./6-alert";
import { Real } from "./5-real";
import FAQ from "./8-faq";
import { Contact } from "./9-contact";
import {  Last } from "./10-last";

const PagesWraper = styled.div`
  width: 100%;
  overflow: hidden;
  /* background-image: url(${bg}); */
`;
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;


const Main = () => {
  const [innerWidthtriger, SetInnerWidthtriger] = useState(window.innerWidth);
  const [innerWidth, SetInnerWidth] = useState(window.innerWidth);
  const canvasRef = useRef();
  const contextRef = useRef();
  const scrollTopRef = useRef();
  const totalFrames = 465;
  useEffect(() => {
    if (innerWidth >= 899) {
      contextRef.current = canvasRef?.current?.getContext("2d");

      const img = new Image();
      img.src = "./eyeFrames/0001.jpg";

      img.onload = () => {
        canvasRef.current.width = window.innerWidth;
        canvasRef.current.height = window.innerHeight;
        contextRef.current.drawImage(
          img,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      };

      const getImageName = (index) =>
        `./eyeFrames/${index.toString().padStart(4, "0")}.jpg`;

      const preloadImages = () => {
        const imageLoadedPromises = [];
        for (let i = 1; i < totalFrames; i++) {
          const img = new Image();
          const imgLoadPromise = new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = () => {
              // console.error(`Image loading error for ${getImageName(i)}`);
            };
          });
          imageLoadedPromises.push(imgLoadPromise);
          img.src = getImageName(i);
        }
        return Promise.all(imageLoadedPromises);
      };

      const updateImage = (index) => {
        img.src = getImageName(index);
        contextRef.current.drawImage(
          img,
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
      };

      const handleScroll = (e) => {
        scrollTopRef.current = document.documentElement.scrollTop;
        const maxScrollDistance =
          document.documentElement.scrollHeight - window.innerHeight;
        const fractionScrolled = scrollTopRef.current / maxScrollDistance;

        const imageIndex = Math.min(
          totalFrames,
          Math.ceil(fractionScrolled * totalFrames)
        );

        requestAnimationFrame(() => updateImage(imageIndex));
      };

      window.addEventListener("scroll", handleScroll);
      preloadImages();
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [innerWidth]);
  const [audioSrc, SetaudioSrc] = useState("Section1");
  const [isPlaying, SetIsPlaying] = useState(false);
  const [isWelcome, SetIsWelcome] = useState(false);
  const [refSection1, inViewSection1] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section1", inView),
  });

  const [refSection2, inViewSection2] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section3", inView),
  });
  const [refSection3, inViewSection3] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section3", inView),
  });

  const [refSection4, inViewSection4] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section4", inView),
  });

  const [refSection5, inViewSection5] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section5", inView),
  });

  const [refSection6, inViewSection6] = useInView({
    triggerOnce: false,
    onChange: (inView) => handleSectionInView("Section6", inView),
  });

  const handleSectionInView = (sectionId, inView) => {
    if (inView) {
      // console.log(`${sectionId} is in the viewport!viewport: ${inView}`);
      if (sectionId === "Section1") {
        SetaudioSrc("Section1");
      } else if (sectionId === "Section2") {
        SetaudioSrc("Section2");
      } else if (sectionId === "Section3") {
        SetaudioSrc("Section3");
      } else if (sectionId === "Section4") {
        SetaudioSrc("Section4");
      } else if (sectionId === "Section5") {
        SetaudioSrc("Section5");
      } else {
        SetaudioSrc("Section6");
      }
    }
  };
  const handleAudioPlay = () => {
    SetIsWelcome(true);
    SetIsPlaying(true);
  };
  const audioRef = useRef(null);

  useEffect(() => {
    // Handle audio source changes here
    const audioElement = audioRef.current;

    if (audioElement) {
      // audioElement.src = ''; // Reset the source
      setTimeout(() => {
        audioElement.load();
      }, 2500); // Load the audio element with the new source

      // Play the audio with a slight delay
      setTimeout(() => {
        audioElement.play().catch((error) => {
          // Handle play error, if any
          // console.error("Error playing audio:", error.message);
        });
      }, 2500);
    }
  }, [audioSrc]);

  const handleWindowResize = () => {
    const windowWidth = window.innerWidth;
    SetInnerWidthtriger(windowWidth);
  };
  useEffect(() => {
    SetInnerWidth(innerWidthtriger);
  }, [innerWidthtriger]);
  // Attach the event listener on component mount
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    // Don't forget to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <PagesWraper style={{ backgroundImage: "#000000" }}>
      {innerWidth >= 899 ? (
        <div>
          <canvas
            className={"canvas-container"}
            ref={canvasRef}
            id="scroll-canvas"
          />
        </div>
      ) : (
      "")}
        <VideoBK autoPlay loop muted style={{mixBlendMode:"screen"}}>
          <source src={bg} type="video/mp4" />
        </VideoBK>

      {!isWelcome && (
        <VideoBK autoPlay loop muted>
          <source src={bg} type="video/mp4" />
        </VideoBK>
      )}

      {/* <BGIVideo hashValue={hashValue} autoPlay loop muted>
        <source src={nostrodallasEye} type="video/mp4" />
      </BGIVideo> */}
      {/* <BGdiv>
        <BGinnerdiv>
          <BGImage hashValue={hashValue} src={header} />
        </BGinnerdiv>
      </BGdiv> */}
      {isPlaying ? (
        <audio ref={audioRef} autoPlay>
          {audioSrc === "Section1" ? (
            <source src={nostro1} type="audio/mp3" />
          ) : audioSrc === "Section2" ? (
            <source src={nostro2} type="audio/mp3" />
          ) : audioSrc === "Section3" ? (
            <source src={nostro3} type="audio/mp3" />
          ) : audioSrc === "Section4" ? (
            <source src={nostro4} type="audio/mp3" />
          ) : audioSrc === "Section5" ? (
            <source src={nostro5} type="audio/mp3" />
          ) : (
            ""
          )}
        </audio>
      ) : (
        ""
      )}
      {!isWelcome ? (
        <Container
          maxWidth="xxl"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "calc(100vh)",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item p="0" xs={12} sm={8}>
              <AllImage mw="100%" src={last} alt="" />
            </Grid>
            <Grid
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              item
              pt="40px"
              xs={12}
            >
              <CustomButton name="Enter" onclick={handleAudioPlay} />
            </Grid>
          </Grid>
        </Container>
      ) : (
        <>
          <Header
            sectionId="Section1"
            refProp={refSection1}
            inView={inViewSection1}
            onChange={handleSectionInView}
          />

          <Leverage
            sectionId="Section2"
            refProp={refSection2}
            inView={inViewSection2}
            onChange={handleSectionInView}
          />

          <About
            sectionId="Section3"
            refProp={refSection3}
            inView={inViewSection3}
            onChange={handleSectionInView}
          />

          <Feature
            sectionId="Section4"
            refProp={refSection4}
            inView={inViewSection4}
            onChange={handleSectionInView}
          />
{/* 
          <Real
            
          /> */}

          <Alert
           sectionId="Section5"
           refProp={refSection5}
           inView={inViewSection5}
           onChange={handleSectionInView}
          />

          <Partner  sectionId="Section6"
            refProp={refSection6}
            inView={inViewSection6}
            onChange={handleSectionInView}/>

          <FAQ />

          <Contact />

          <Last />
        </>
      )}
    </PagesWraper>
  );
};
export default Main;
