import styled from '@emotion/styled';
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import Arrowleft from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowRight from '@mui/icons-material/ArrowBackIosOutlined';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Link} from 'react-router-dom';

const MainGrid = styled(Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 2% 0% 0%;
`
const CommunityTitle = styled(Typography)`
    color: #FFFFFF;
    font-family: poppins-semibold;
    @media (max-width: 600px){
        display: none;
    }
`
const NameTrade = styled (Typography)`
    color: ${props => props.textcolor? props.textcolor : '#707070'};
    font-family: GothicR;
`
const MainBox = styled(Box)`
    display: flex;
    align-items: center;
`
const CardDetails = styled(Typography)`
    color: #FFFFFF;
    font-family: GothicR;
    text-align: ${props => props.ta? props.ta : 'center'};
    @media (max-width: 600px){
        font-size: 15px;

    }
`
const DetailGrid = styled( Grid)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 10px 0px;
    @media (max-width: 600px){
        display: none; 
    }
`
const CommuintyDetails = (props) => {
    const {id, traderimg, investment, roi, winrate, totaltrades, returnamount, position} = props;
    
    useEffect(() => {
        AOS.init({
          duration: 800,
          offset: 200,
        });
      }, []);
    
      useEffect(() => {
        AOS.refresh();
      });
    return(
        <div>
            <Container maxWidth='lg'>
                <Grid container>
                    <MainGrid xs={12} data-aos='fade-up' >
                        <MainBox>
                        <CommunityTitle variant='h4'>Community Traders</CommunityTitle>
                        <Arrowleft sx={{color: 'white', padding: '0px 10px'}}/>
                        <NameTrade variant='subtitle-1'>{id}</NameTrade>
                        </MainBox>
                        <MainBox>
                            <ArrowRight sx={{color: 'white', padding: '0px 10px'}}/>
                            <NameTrade variant ='subtitle-2' textcolor = '#FFFFFF'><Link to='/wofMain' style={{textDecoration: 'none', color: 'white'}}>Back</Link></NameTrade>
                        </MainBox>
                    </MainGrid>
                    <Grid xs={12} sx={{padding: '2% 0%'}}  data-aos="fade-right">
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <img src={traderimg} alt=''/>
                        <CardDetails ta='left'><span style={{ fontFamily: 'poppins-semibold' }}>{id}</span><br/>0Xxxxxxx00000000000000000</CardDetails>
                        </Box>
                        <DetailGrid xs={12}>
                            <CardDetails>{investment}<br/>Investment</CardDetails>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58'}}>{roi}</span><br/>ROI</CardDetails>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58'}}>{winrate}</span><br/>Win Rate</CardDetails>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{totaltrades}</span><br/>Total Trades</CardDetails>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{returnamount}</span><br/>Return</CardDetails>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{position}</span><br/>Position</CardDetails>
                        </DetailGrid>
                </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default CommuintyDetails