import styled from "@emotion/styled";
import { Button } from "@mui/material";
import React from "react";

const ButtonStyle  = styled(Button)`
    background-color: #0000;
    color: #AC412A;
    border-right: 1px solid #AC412A;
    border-left: 1px solid rgb(172, 65, 42);
    text-transform: none;
    box-shadow: none;
    :hover{
        background-color:  #0000;
        color: #FFFFFF;
    }
    
`
export const CustomButton = (props) => {
    const {name,onclick} = props;
    return(
        <ButtonStyle variant="contained" onClick={onclick}>{name}</ButtonStyle>
    )
}