import { Container, Grid } from '@mui/material';
import React from 'react';
import P1 from '../assets/bitget.png';
import P2 from '../assets/uniswap.png';
import P3 from '../assets/metamask.png';
import P4 from '../assets/trust.png';
import P5 from '../assets/dex.png';
import P6 from '../assets/dexscreener.png';
import P7 from '../assets/etherscan.png';
import styled from '@emotion/styled';
import { Heading } from '../components/heading';
import { AllImage } from '../styling/style';

const CGrid = styled(Grid)`
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
    @media (max-width: 599px){
        padding: 5% 0%;
    }
`
const LogoImage = styled(AllImage)`
     @media (max-width: 599px){
       max-width: ${props => props.mws? props.mws : ''};
    }
`
const Partner = () => {
    return(
        <Container
            sx={{ minHeight: "calc(100vh)" ,display:"grid",placeItems:"center"}}>
            <Grid container >
                {/* <Heading
                    title = 'Partners'
                    pd = '1% 0% 5%'
                /> */}
                <CGrid container  data-aos="fade-up" data-aos-duration="1000"> 
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='100px' mw = '160px'  src={P1}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='100px' mw = '160px' src={P2}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='140px' mw = '160px' src={P3}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='140px' mw = '160px' src={P4}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='100px' mw = '160px' src={P5}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='100px' mw = '160px' src={P6}/>
                    </Grid>
                    <Grid item xs={6} md={3} sm={4} py={2}>
                        <LogoImage mws='120px' mw = '160px' src={P7}/>
                    </Grid>
                </CGrid>
            </Grid>
        </Container>
    )
}
export default Partner;