import { Box, Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import { Heading } from "../components/heading";
import styled from "@emotion/styled";

const GridStyle = styled(Box)`
  /* background: linear-gradient(to top, #ac422a46 30%, transparent 70%); */
  /* opacity: 0.5; */
  padding: 30px 30px;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
  display: grid;
  place-items: center;
`;
export const Real = ({
  sectionId,
  inView,
  onChange,
  children,
  refProp,
  SetaudioSrc,
}) => {
  const ref = useRef();
  return (
    <Container
      id="realtimeupdate"
      maxWidth="xxl"
      ref={refProp || ref}
      sx={{ display: "grid", placeItems: "center", minHeight: "calc(105vh)" }}
    >
      {inView && onChange(sectionId, inView)}
      <Grid container>
        <GridStyle>
          <Heading
            pd="3% 0%"
            title="Real Time Update"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
        </GridStyle>
      </Grid>
    </Container>
  );
};
