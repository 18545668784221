import { Container, Grid } from '@mui/material';
import React from 'react';
import Accordian from '../components/accordian';
import { useEffect } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { Heading } from '../components/heading';

const FAQ = () => {
    useEffect(() => {
        AOS.init({
          duration: 800,
          offset: 200,
        });
      }, []);
    
      useEffect(() => {
        AOS.refresh();
      });
    return(
        <Container maxWidth = 'lg' id='faq' sx={{minHeight:"calc(100vh)",display:"grid",placeItems:"center"}}>
          <Grid container>
            <Heading
              title = 'FAQ'
              p = '1% 0% 1%' data-aos="fade-up" data-aos-duration="1000"
            />
            <Grid item xs={12} data-aos="fade-up" data-aos-duration="1300">
              <Accordian/>
            </Grid>
          </Grid>
        </Container>
    )
}
export default FAQ;