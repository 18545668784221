import { Box, Container, Grid } from "@mui/material";
import React from "react";
import Cards from "../components/cards";
import { AllImage, Text } from "../styling/style";
import { Heading } from "../components/heading";
import last from "../assets/last.png";
import styled from "@emotion/styled";

export const GridStyle = styled(Box)`
  /* background: linear-gradient(to top, #ac422a46 30%, transparent 70%); */
  /* opacity: 0.5; */
  padding: ${(props) => (props.p ? props.p : "30px 30px")};
  border-radius: 10px;
  max-width: 100%;
  display: grid;
  place-items: center;
`;
export const Last = () => {
  return (
    <Container maxWidth="xxl">
      <Grid
        container
        sx={{ display: "grid", placeItems: "center", minHeight: "calc(100vh)" }}
      >
        <GridStyle data-aos="fade-up" data-aos-duration="1000">
          <AllImage mw="100%" src={last} alt="" />
        </GridStyle>
      </Grid>
    </Container>
  );
};
