import styled from '@emotion/styled';
import { Box, Button, Grid, Typography } from '@mui/material';

const CardGrid = styled.div`
    background: linear-gradient( to bottom, transparent 40%, #ac422a83 100% );
    border-radius: 20px;
    margin: 2% 0%;
    padding: 20px ;
`
const Date = styled(Typography)`
    color: #707070;
    font-size: 14px;
    font-family: GothicR;
`
const ButtonStyled = styled(Button)`
    background-color: ${props => props.bg ? props.bg : ''};
    color: white;
    text-transform: none;
    padding: 2px 13px;
    margin: 5px 10px;
    font-size: 10px;
    &:hover{
        background-color: black;
    }
`
const BoxStyle = styled(Box)`
    display: flex;
    align-items: center;
    @media (max-width: 750px){
        flex-direction: column;
    }
`
const Cards = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 750px){
        flex-direction: column;
    }
`
const TabCard = (props)=> {
    const {image, children, date, popup} = props
    return(
        <CardGrid>
            <Cards xs={12}>
            <BoxStyle >
                <img src={image}/>
                <ButtonStyled variant='contained' bg='#707070'>isolated</ButtonStyled>
                <ButtonStyled variant='contained' bg='#37ff5867'>Buy 20.0X</ButtonStyled>
                <Date>{date}</Date>
            </BoxStyle>
            <Button>{popup}</Button>
            </Cards>
            <Grid xs={12}>
                {children}
            </Grid>
        </CardGrid>
    )
}
export default TabCard;
