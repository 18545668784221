import React from "react";
import styled from "@emotion/styled";
import Background from "../assets/header-background.png";
import CommuintyDetails from "./commuintytraders";
import BasicTabs from "../components/communitytabs";
import Popup1 from "../assets/popup1.png";
import Card1 from "../assets/card1.png";

const BackgroundDiv = styled.div`
  /* background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center; */
`;

const Card1Detail = () => {
  const ongoingCard1 = {
    symbol: "BTUSDT",
    buy: "Buy",
    buysell: "20.0X",
    pnl: "PnL%(Closed)",
    leverage: "0.99%",
    avgCost: "27,007.7 USDT",
    closePrice: "27,089.9 USDT",
    imageSrc: Popup1,
    name: "@Cryptonoan1",
  };
  const ongoingCard2 = {
    symbol: "ETHUSDT",
    buy: "Buy",
    buysell: "20.0X",
    pnl: "PnL%(Closed)",
    leverage: "4.99%",
    avgCost: "1,647.2 USDT",
    closePrice: "1,620 USDT",
    imageSrc: Popup1,
    name: "@Cryptonoan1",
  };
  return (
    <div style={{ maxHeight: "calc(100vh - 140px)", overflow: "auto",paddingTop:"70px" }}>
      <BackgroundDiv>
        <CommuintyDetails
          id="@Cryptonoan"
          traderimg={Card1}
          investment="$1,500"
          roi="+250%"
          winrate="60%"
          totaltrades="4"
          returnamount="$2,250"
          position="Open"
        />
      </BackgroundDiv>
      <BasicTabs
        symbol="BTUSDT"
        buy="Buy"
        buysell="20.0X"
        pnl="PnL%(Closed)"
        leverage="6.54%"
        avgCost="27,007.7 USDT"
        closePrice="27,089.9 USDT"
        imageSrc={Popup1}
        name="@Cryptonoan"
        ongoingCard1={ongoingCard1}
        ongoingCard2={ongoingCard2}
      />
    </div>
  );
};
export default Card1Detail;
