import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useRef } from "react";
import { Heading } from "../components/heading";
import styled from "@emotion/styled";
import PhoneImage from "../assets/phoneImg.png";
import Feature1 from "../assets/f1.png";
import Feature2 from "../assets/f2.png";
import Feature3 from "../assets/f3.png";
import Feature4 from "../assets/f4.png";
import Cards from "../components/cards";
import { AllImage } from "../styling/style";


const BoxStyle = styled(Box)`
  background: linear-gradient(to top, #ac422a46 30%, transparent 70%);
  /* border: 1px solid #AC412A; */
  padding: 20px 20px 0px;
  margin: 5px auto 0px;
  border-radius: 20px 20px;
`;
const GridStyle = styled(Grid)`
  display: flex;
  justify-content: center;
  margin: auto;
  @media (max-width: 900px) {
    margin-bottom: 20px;
  }
`;
const BDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 700px) and (max-width: 900px) {
    justify-content: center;
  }
`;
export const Feature = ({ sectionId, inView, onChange, children, refProp }) => {
  const ref = useRef();
  return (
    <Container  id="feature" maxWidth="xl"  ref={refProp || ref}
    
    sx={{ minHeight: "calc(105vh)" ,display:"flex",alignItems:"start",justifyContent:"center",pt:"80px"}}>
      {inView && onChange(sectionId, inView)}
      <Grid
        container
      >
        <Heading pd="0% 0%" title="Features" data-aos="fade-up" data-aos-duration="1000"/>
        <Grid item xs={12} md={4} order={{ xs: 2, sm: 2, md: 1 }} data-aos="fade-up" data-aos-duration="1300">
          <Cards
            image={Feature2}
            tag="Real Time Price Update"
            data={[["Know how your bot is performing at any given moment."]]}
          />
          <div style={{ paddingTop: "0px" }}>
            <Cards
              image={Feature1}
              tag="100% Secure"
              data={[["Your security is our priority; trade with confidence on Nostradaums"]]}
            />
          </div>
        </Grid>
        <GridStyle item xs={12} sm={6} md={4} order={{ xs: 1, sm: 1, md: 2 }} data-aos="fade-up" data-aos-duration="1600">
          <BoxStyle>
            <Typography
              color="white"
              sx={{ fontSize: "30px", padding: "10px 0px" }}
            >
              TELEGRAM BOT
            </Typography>
            <Typography sx={{ color: "white", fontSize: "16px" }}>
              Handle your future trades autonomously in an easily accessible
              manner.
            </Typography>
            <BDiv>
              <AllImage mw="50%" src={PhoneImage} />
            </BDiv>
          </BoxStyle>
        </GridStyle>
        <Grid item xs={12} md={4} order={{ xs: 3, sm: 3, md: 3 }} data-aos="fade-up" data-aos-duration="1900">
          <Cards
            image={Feature3}
            tag="Limit/Market Orders"
            data={[["Execute precise trades that automatically close where you need them to."]]}
          />
          <div style={{ paddingTop: "0px" }}>
            <Cards
              image={Feature4}
              tag="Leverage Up To 20X"
              data={[["Boost your trading potential with up to 20X leverage on Nostradaums!"]]}
            />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};
