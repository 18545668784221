import { Drawer, Box, Typography, IconButton, Grid} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import styled from '@emotion/styled';
import Logo from '../assets/logo.png';
import { AllImage } from '../styling/style';

const Menustyling = styled.div`
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 0px;
`;
const Liststyling = styled.li`
padding: 10px 12px;
display: flex;
list-style: none;
display: block;
text-align: left;
padding: ${props => props.pd? props.pd : '10px 10px'};
`;
const Listdrawer = styled.a`
color: #AC412A;
text-decoration: none;
font-size: 18px; 
margin: 1rem 0;
padding: 8px 12px;
font-family: GothicR;
`;
const NavBtn = styled.div`
padding: 0px;
display: none;
cursor: pointer;
font-size: 1.8rem; 
color: #AC412A;
@media (max-width: 860px){
    display: block;
}
`;
const GridS = styled(Grid)`
    padding: 30px 15px 30px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #AC412A;
`;

export const DrawerMain = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const toggleDrawer = (open) => (event) => {setIsDrawerOpen(open)};
    return (
        <>
        <NavBtn>
            <IconButton className="nav-btn" size='large' edge='start' color='inherit' aria-label='logo' onClick={toggleDrawer(true)}>
                <MenuIcon/>
            </IconButton>
            </NavBtn>
            <Drawer
                anchor='left'
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                className="drawer"
            >
            <div style={{backgroundColor: '#070707', overflow: 'hidden'}}>
                <Box p={2} padding='0px' width='250px' textAlign='center' role='presentation'>
                    <GridS item xs={12} >
                        <AllImage mw='70px' src={Logo}/>
                    </GridS>
                    <Menustyling onClick={() => setIsDrawerOpen(false)}>
                        <Typography variant='h6' component='div'></Typography>
                        <Liststyling><Listdrawer href="/#home">Home</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/#about">About</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/#feature">Features</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/#realtimeupdate">Real Time Update</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/#leverge">Token Details</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/#contact">Contact</Listdrawer></Liststyling>
                        <Liststyling><Listdrawer href="/walloffame">Wall Of Fame</Listdrawer></Liststyling>
                        
                    </Menustyling>
                </Box>
            </div>
            </Drawer>
        </>
    );
};