import React from "react";
import styled from "@emotion/styled";
import Background from '../assets/header-background.png';
import CommuintyDetails from "./commuintytraders";
import BasicTabs from "../components/communitytabs";
import Card3 from '../assets/card3.png';
import Popup3 from '../assets/popup3.png'

const BackgroundDiv = styled.div`
    /* background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; */
`

const CardDetail3 = () => {
    const ongoingCard1 = {
        symbol: 'BTUSDT',
        buy: 'Buy',
        buysell: '20.0X',
        pnl: 'PnL%(Closed)',
        leverage: '0.99%',
        avgCost: '27,007.7 USDT',
        closePrice: '27,089.9 USDT',
        imageSrc: Popup3,
        name: '@overdose_gems_group',
    }
    const ongoingCard2 = {
        symbol: 'ETHUSDT',
        buy: 'Buy',
        buysell: '20.0X',
        pnl: 'PnL%(Closed)',
        leverage: '4.99%',
        avgCost: '1,647.2 USDT',
        closePrice: '1,620 USDT',
        imageSrc: Popup3,
        name: '@overdose_gems_group',
    }
    return(
        <div  style={{ maxHeight: "calc(100vh - 140px)", overflow: "auto",paddingTop:"70px" }}>
            <BackgroundDiv>
                    <CommuintyDetails
                        id='@overdose_gems_group'
                        traderimg = {Card3}
                        investment = '$1,500'
                        roi = '+271.5%'
                        winrate = '75%'
                        totaltrades = '8'
                        returnamount = '$3,710'
                        position = 'Open'
                    />
            </BackgroundDiv>
            <BasicTabs
            symbol= 'ETHUSDT'
            buy = 'Buy'
            buysell = '20.0X'
            pnl = 'PnL%(Closed)'
            leverage = '7.25%'
            avgCost = '27,007.7 USDT'
            closePrice = '27,089.9 USDT'
            imageSrc = {Popup3}
            name = '@overdose_gems_group'
            ongoingCard1 = {ongoingCard1}
            ongoingCard2={ongoingCard2}
            />
        </div>
    )
}
export default CardDetail3;