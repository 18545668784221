import logo from "./logo.svg";
import "./App.css";
import Main from "./sections/main";
import WallOfFame from "./page 2/9-wallOfFlame";
import bg from "./assets/bg.mp4";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom"; // Import BrowserRouter
import Layout from "./components/layout";
import Card1Detail from "./page 3/card1";
import CardDetail2 from "./page 3/Card2";
import CardDetail3 from "./page 3/card3";
import CardDetail4 from "./page 3/card4";
import CardDetail5 from "./page 3/card5";
import CardDetail6 from "./page 3/card6";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
const VideoBK = styled.video`
  /* width: 100%;
  height: auto;
  display: block; */
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: -1;
`;
function App() {

  return (
    <div>
      
      {/* <WallOfFame/> */}
      <VideoBK autoPlay loop muted>
        <source src={bg} type="video/mp4" />
      </VideoBK>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/walloffame" element={<WallOfFame />} />
            <Route
              path="/communityTradersMain/@cryptonoan"
              element={<Card1Detail />}
            />
            <Route
              path="/communityTradersMain/@drbuny"
              element={<CardDetail2 />}
            />
            <Route
              path="/communityTradersMain/@overdose_gems_group"
              element={<CardDetail3 />}
            />
            <Route
              path="/communityTradersMain/@spicygems"
              element={<CardDetail4 />}
            />
            <Route
              path="/communityTradersMain/@crazylandinhabitant"
              element={<CardDetail5 />}
            />
            <Route
              path="/communityTradersMain/@ricardo"
              element={<CardDetail6 />}
            />
            <Route index element={<Main />} />

            {/* Add a catch-all route to handle 404 errors */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
