import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import PlusIcon from "@mui/icons-material/ControlPointOutlined";
import MinusIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import styled from "@emotion/styled";

const AccordianTypo = styled(Typography)`
  color: #ffffff;
  font-family: GothicR;
`;
const AccordianDetail = styled(Typography)`
  color: #ffffff;
  font-family: GothicR;
  font-size: 15px;
`;
export default function Accordian() {
  const [panel1Open, setPanel1Open] = React.useState(false);
  const [panel2Open, setPanel2Open] = React.useState(false);
  const [panel3Open, setPanel3Open] = React.useState(false);
  const [panel4Open, setPanel4Open] = React.useState(false);

  const HandlePanel1Change = () => {
    setPanel1Open(!panel1Open);
  };
  const HandlePanel2Change = () => {
    setPanel2Open(!panel2Open);
  };
  const HandlePanel3Change = () => {
    setPanel3Open(!panel3Open);
  };
  const HandlePanel4Change = () => {
    setPanel4Open(!panel4Open);
  };

  return (
    <div>
      <Accordion
        sx={{ backgroundColor: "transparent", borderBottom: "1px solid white" }}
        expandIcon={panel1Open}
        onChange={HandlePanel1Change}
      >
        <AccordionSummary
          sx={{ padding: "10px 5px" }}
          expandIcon={
            panel1Open ? (
              <MinusIcon sx={{ color: "white" }} />
            ) : (
              <PlusIcon sx={{ color: "white" }} />
            )
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <AccordianTypo sx={{ color: "#ffffff" }}>
            What is Nostradamus ?
          </AccordianTypo>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
          <AccordianDetail sx={{ color: "#ffffff" }}>
            Nostradamus is an innovative Telegram-based cryptocurrency trading
            bot designed to simplify and enhance margin trading in the
            cryptocurrency market. It serves as a user-friendly trading
            companion, empowering traders of all levels to optimize their
            trading strategies and navigate the complexities of margin trading
            with ease.
          </AccordianDetail>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expandIcon={panel2Open}
        onChange={HandlePanel2Change}
        sx={{ backgroundColor: "transparent", borderBottom: "1px solid white" }}
      >
        <AccordionSummary
          sx={{ padding: "10px 5px" }}
          expandIcon={
            panel2Open ? (
              <MinusIcon sx={{ color: "white" }} />
            ) : (
              <PlusIcon sx={{ color: "white" }} />
            )
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <AccordianTypo sx={{ color: "#ffffff" }}>
            How It works ?
          </AccordianTypo>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
          <AccordianDetail sx={{ color: "#ffffff" }}>
            Nostradamus operates through the Telegram platform, providing
            users with an intuitive interface to set up and customize their
            margin trading strategies. Users can configure their preferred
            trading parameters, such as leverage, trading pairs, and risk
            management settings. Once configured, Nostradamus executes trades
            based on these predefined strategies, removing emotional bias from
            trading decisions. The bot leverages real-time market data and
            advanced risk management algorithms to enhance profitability and
            protect users' capital.
          </AccordianDetail>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expandIcon={panel3Open}
        onChange={HandlePanel3Change}
        sx={{ backgroundColor: "transparent", borderBottom: "1px solid white" }}
      >
        <AccordionSummary
          sx={{ padding: "10px 5px" }}
          expandIcon={
            panel3Open ? (
              <MinusIcon sx={{ color: "white" }} />
            ) : (
              <PlusIcon sx={{ color: "white" }} />
            )
          }
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <AccordianTypo sx={{ color: "#ffffff" }}>
            Can I deposit from an exchange?
          </AccordianTypo>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
          <AccordianDetail sx={{ color: "#ffffff" }}>TBA</AccordianDetail>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expandIcon={panel4Open}
        onChange={HandlePanel4Change}
        sx={{ backgroundColor: "transparent" }}
      >
        <AccordionSummary
          sx={{ padding: "10px 5px" }}
          expandIcon={
            panel4Open ? (
              <MinusIcon sx={{ color: "white" }} />
            ) : (
              <PlusIcon sx={{ color: "white" }} />
            )
          }
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <AccordianTypo sx={{ color: "#ffffff" }}>
            Where my funds will be held when a trade is open?{" "}
          </AccordianTypo>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: "8px 0px 16px" }}>
          <AccordianDetail sx={{ color: "#ffffff" }}>
            When a trade is open, your funds are typically held within your
            connected exchange account. Nostradamus acts as a trading tool
            that connects to your exchange accounts to execute trades based on
            your configured strategies. Your funds remain under your control
            within these exchanges, ensuring transparency and security. The bot
            does not hold or manage your funds; instead, it facilitates trade
            execution on your behalf within the exchange platforms you've
            connected.
          </AccordianDetail>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
