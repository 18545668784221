import React from "react";
import Menu from "./menu";
import { Container, Link, Typography } from "@mui/material";
import { AllImage } from "../styling/style";
import footerX from "../assets/footerX.png";
import footerTG from "../assets/footerTG.png";
import gitbook from "../assets/gitbook.png";

const Layout = ({ children }) => {
  return (
    <div>
      <Menu />
      <div
        sx={{
          padding: "0 !important",
          margin: "0 !important",
          maxHeight: "calc(100vh - 102px)",
        }}
      >
        {children}
      </div>
      <Container
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          bottom: "0",
          right: "50%",
          zIndex: "999999999",
          transform: "translate(50%,0%)",
        }}
      >
        <Typography fontSize="12px" color="#AC412A">
          © 2023 Nostradamus
        </Typography>
        <Typography fontSize="12px" color="#AC412A">
          Email: info@nostrabot.com
        </Typography>
        <div>
          <Link href="https://twitter.com/nostrabot_eth" target="blank">
            <AllImage src={footerX} mw="30px" />
          </Link>
          <Link href="https://t.me/nostrabot_eth" target="blank">
            <AllImage src={footerTG} mw="30px" />
          </Link>
          <Link href="https://nostra.gitbook.io/nostra-bot-information-repository" target="blank">
            <AllImage src={gitbook} mw="30px" />
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default Layout;
