import { Box, Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import styled from "@emotion/styled";
import {  Text } from "../styling/style";


const BoxGrid = styled(Box)`
  background: linear-gradient(to top, #ac422a46 30%, transparent 70%);
  border-radius: 10px;
  max-width: 250px;
  margin: 5% 0%;
  padding: 20px 20px;
  /* border: 1px solid #AC412A; */
`;
const GridStyle = styled(Grid)`
  /* margin: auto; */
`;
export const Leverage = ({ sectionId, inView, onChange, children, refProp }) => {
  const ref = useRef();
  return (
    <Container id="leverge"  maxWidth="xl"  ref={refProp || ref}>
      {inView && onChange(sectionId, inView)}
      <Grid container sx={{display:"flex",alignItems:"start",justifyContent:"end",minHeight:"calc(105vh)",pt:"50px"}}>
        <GridStyle item xs={12} sm={3} md={3} p={1}>
          <BoxGrid  data-aos="fade-up" data-aos-duration="1000">
            <Text variant="h4" padding="7% 0%" ta="left">
              20X
            </Text>
            <Text variant="body" ta="left">
              Leverage Up To
            </Text>
          </BoxGrid>
          <BoxGrid data-aos="fade-up" data-aos-duration="1300">
            <Text variant="h4" padding="7% 0%" ta="left">
              $201K+
            </Text>
            <Text variant="body" ta="left">
              Trading Volume
            </Text>
          </BoxGrid>
        </GridStyle>
        <GridStyle item xs={12} sm={3} md={3} p={1}>
          <BoxGrid data-aos="fade-up" data-aos-duration="1600">
            <Text variant="h4" padding="7% 0%" ta="left">
              2
            </Text>
            <Text variant="body" ta="left">
              Available Pairs
            </Text>
          </BoxGrid>
          <BoxGrid data-aos="fade-up" data-aos-duration="1900">
            <Text variant="h4" padding="7% 0%" ta="left">
              2.3K+
            </Text>
            <Text variant="body" ta="left">
              Unique Address
            </Text>
          </BoxGrid>
        </GridStyle>
      </Grid>
    </Container>
  );
};
