import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import HeaderBackground from "../assets/header.png";
import { Text } from "../styling/style";

const BackgroundGrid = styled(Grid)`
  /* background-image: url(${HeaderBackground});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; */
  min-height: 91vh;
`;
const GridStyle = styled(Box)`
  /* background: linear-gradient(to top, #ac422a46 30%, transparent 70%); */
  /* opacity: 0.5; */
  padding: 30px 30px;
  border-radius: 10px;
  max-width: 800px;
  margin: auto;
`;
export const Header = ({ sectionId, inView, onChange, children, refProp }) => {
    const ref = useRef();
  return (
    <Container id="home" sx={{
        minHeight: "calc(105vh)",
      }} maxWidth="lg"  ref={refProp || ref}>
        {inView && onChange(sectionId, inView)}
      <BackgroundGrid container>
        <Grid
          container
          sx={{
            display: "grid",
            placeItems: "center",
            minHeight: "calc(100vh)",
          }}
        >
          <GridStyle  data-aos="fade-up">
            <Text variant="h4" sx={{ zIndex: "1" }} fs600="35px">
              Nostradamus allows you to experience margin trading like never
              before. Optimize your cryptocurrency trading today.
            </Text>
          </GridStyle>
        </Grid>
      </BackgroundGrid>
    </Container>
  );
};
