import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { AllImage } from '../styling/style';

const BoxStyle = styled(Box)`
    background: linear-gradient(to top, #ac422a46 10%, transparent 70%);
    min-height: ${props => props.minh? props.minh : ''};
    max-width: ${props => props.mw? props.mw : '250px'} ;
    border-radius: 20px 20px;
    padding: ${props => props.pd? props.pd : '20px'};
    margin: 20px auto;
`
const Para = styled(Typography)`
    text-align: ${props=>props.ta? props.ta : ''};
    color: white;
    font-size: 14px;
    padding: 5px 0px;
    margin: ${props => props.m? props.m : ''};
`
const Para1 = styled(Typography)`
    color: white;
    font-size: 18px;
    background: ${props => props.bg? props.bg : ''} ;
    border-radius: 10px;
    padding: ${props => props.p? props.p : ''};
`
const Disc  = (props)=> <Para>{props.detail}</Para>
const Cards = (props) => {
    const{tag, image, data} = props;
    return(
        <BoxStyle xs={12} md={4} {...props} >
            <AllImage mw='50px' src={image}/>
            <Para1 variant='h6' {...props}>{tag}</Para1>
            <div>
            {data?.map((val, ind)=> {
                return <Disc key={ind} detail={val[0]}/>
            })}
            </div>
        </BoxStyle>
    )
}

export default Cards;