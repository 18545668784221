import { Container, Grid } from '@mui/material'
import React, { useRef } from 'react'
import Cards from '../components/cards'
import { Heading } from '../components/heading';

export const Alert = ({ sectionId, inView, onChange, children, refProp ,SetaudioSrc}) => {
    const ref = useRef();
    return(
        <Container maxWidth='lg'  ref={refProp || ref}>
            {inView && onChange(sectionId, inView)}
            <Grid container sx={{padding: '5% 0%'}}>
            <Heading
            pd="3% 0%"
            title="Real Time Update"
            data-aos="fade-up"
            data-aos-duration="1000"
          />
                <Grid item xs={12} data-aos="fade-up" data-aos-duration="1000">   
                    <Cards
                    ta='center'
                       mw='400px'
                       tag='Price Alerts'
                       data= {[['Set thresholds for assets and be alerted on price hits.']]}
                    />
                </Grid>
                <Grid  item xs={12} data-aos="fade-up" data-aos-duration="1300">   
                    <Cards
                       mw='400px'
                       tag='Order Execution Alerts'
                       data= {[['Get instant notifications on completed orders.']]}
                    />
                </Grid>
                <Grid item xs={12} data-aos="fade-up" data-aos-duration="1600">   
                    <Cards
                       mw='400px'
                       tag='Liquidation Alerts'
                       data= {[["Don't risk losing it all at once. Our timely alerts ensure that users have a window to close their trades before their funds are liquidated!"]]}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}