import styled from "@emotion/styled";
import { Box, Container, Grid, TextField, Typography } from "@mui/material";
import React from "react";
import HeaderBackground from "../assets/header.png";
import { Text } from "../styling/style";
import { Heading } from "../components/heading";
import { CustomButton } from "../components/button";

const BackgroundGrid = styled(Grid)`
  /* background-image: url(${HeaderBackground});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; */
  /* min-height: 100vh; */
  display: grid;
  place-items: center;
`;
const GridStyle = styled(Grid)`
  background: linear-gradient(to top, #ac422a46 30%, transparent 70%);
  /* opacity: 0.5; */
  width: 100%;
  padding: 30px 30px;
  border-radius: 10px;
  margin: auto;
  gap: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const INPUT = styled.input`
  background: #15151525;
  /* opacity: 0.5; */
  filter: brightness(5);
  padding: 20px 10px;
  width: 98%;
  max-width: 100%;
  border-radius: 10px;
  border: none !important;
  :focus {
    outline: none !important;
    outline: none !important;
  }
`;
const TextINPUT = styled.textarea`
  background: #15151525;
  /* opacity: 0.5; */

  filter: brightness(5);
  padding: 20px 10px;
  width: 98%;
  height: 100px;
  border-radius: 10px;
  border: none !important;
  :focus {
    outline: none !important;
    outline: none !important;
  }
`;
export const Contact = () => {
  return (
    <Container
      id="contact"
      maxWidth="lg"
      sx={{
        minHeight: "calc(100vh)",
        overflow: "hidden",
        display: "grid",
        placeItems: "center",
      }}
    >
      <BackgroundGrid container>
        <Grid container sx={{ margin: "" }}>
          <Heading pd="0% 0%" title="Contact" data-aos="fade-up" data-aos-duration="1000"/>
          <GridStyle container item xs={12} md={10} data-aos="fade-up" data-aos-duration="1300">
            <Grid item xs={12} sm={5}>
              <Typography sx={{ color: "white", fontSize: "16px" }}>
                Telegram Username
              </Typography>
              <INPUT
                id="outlined-basic"
                variant="outlined"
                placeholder="e.g. @xyz"
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography sx={{ color: "white", fontSize: "16px" }}>
                Subject
              </Typography>
              <INPUT
                id="outlined-basic"
                variant="outlined"
                placeholder="Subject"
              />
            </Grid>
            <Grid item xs={12} sm={10.5}>
              <Typography sx={{ color: "white", fontSize: "16px" }}>
                Message
              </Typography>
              <TextINPUT
                id="outlined-basic"
                variant="outlined"
                placeholder="Your message"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10.5}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              <CustomButton name="Send" />
            </Grid>
          </GridStyle>
        </Grid>
      </BackgroundGrid>
    </Container>
  );
};
