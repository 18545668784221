import { Box, Container, Grid } from "@mui/material";
import React, { useRef } from "react";
import { AllImage, ListItems, ListStyle, Ulist } from "../styling/style";
import styled from "@emotion/styled";
import Logo from "../assets/logo.png";
import Background from "../assets/header-background.png";
import { CustomButton } from "./button";
import { DrawerMain } from "./drawer";

const BackgroundDiv = styled.div`
  /* background-image: url(${Background});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center; */
  min-height: 113vh;
  @media (max-width: 599px) {
    min-height: 100vh;
  }
  @media (min-width: 1800px) {
    min-height: 84vh;
  }
  @media (min-width: 800px) and (max-width: 900px) {
    min-height: 125vh;
  }
  @media (min-width: 600px) and (max-width: 800px) {
    min-height: 100vh;
  }
`;

const MainGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
`;

const IconBox = styled(Box)`
  @media (max-width: 950px) {
    display: none;
  }
`;
const NavGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Menu = () => {
  return (
    <Container
      maxWidth="xxl"
      sx={{ zIndex: "99", padding: "0 !important", position: "fixed" }}
    >
      <Container sx={{ padding: "0 !important" }}>
        <Grid container>
          <NavGrid item xs={12}>
            <ListItems href="/">
              <AllImage mw="50px" src={Logo} />
            </ListItems>

            <Ulist>
              <ListStyle>
                <ListItems href="/#home">Home</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/#about">About</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/#feature">Features</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/#realtimeupdate">Real Time Update</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/#leverge">Token Details</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/#contact">Contact</ListItems>
              </ListStyle>
              <ListStyle>
                <ListItems href="/walloffame">Wall Of Fame</ListItems>
              </ListStyle>
              <ListStyle>
                <CustomButton name="Open Bot" />
              </ListStyle>
            </Ulist>
            <DrawerMain />
          </NavGrid>
        </Grid>
      </Container>
    </Container>
  );
};
export default Menu;
