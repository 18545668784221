import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Heading } from "../components/heading";
import Background from "../assets/header-background.png";
import styled from "@emotion/styled";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommunityTraders from "./wof2-comm-traders";

const BackgroundDiv = styled.div`
  /* background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center; */
`;
const FameDesc = styled(Typography)`
  font-family: GothicR;
  color: #ffffff;
`;
const FameGrid = styled(Grid)`
  text-align: center;
  margin: auto;
  max-width: 1000px;
  padding: 3% 0%;
`;
const WallOfFame = () => {
  useEffect(() => {
    AOS.init({
      duration: 800,
      offset: 200,
    });
  }, []);

  useEffect(() => {
    AOS.refresh();
  });
  return (
    <div style={{maxHeight:"calc(100vh - 115px)", overflow:"auto",paddingTop:"70px"}}>
      <BackgroundDiv>
        <Container maxWidth="lg">
          <Grid Container>
            <Heading title="Wall Of Fame" />
            <FameGrid xs={12} data-aos="zoom-in-up" data-aos-duration="1000">
              <FameDesc variant="subtitle-1">
                Our Wall of Fame is a prestigious platform dedicated to
                celebrating the remarkable achievements of our community
                traders. Here, we shine a spotlight on the individuals who have
                not only demonstrated unwavering dedication to Nostradamus but
                have also showcased their exceptional skills in the world of
                cryptocurrency trading. Explore the strategies and successes of
                our featured traders as they take their well-deserved place on
                the Wall of Fame.
              </FameDesc>
            </FameGrid>
          </Grid>
        </Container>
      </BackgroundDiv>
      <CommunityTraders />
    </div>
  );
};
export default WallOfFame;
