import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';
import FavouriteIcon from '@mui/icons-material/StarBorderOutlined';
import { Link } from 'react-router-dom';

const CardGrid = styled.div`
    background: linear-gradient( to bottom, transparent 40%, #ac422a83 100% );
    border-radius: 20px;
    margin: 10% 0%;
    padding: 20px ;
`
const NameTypo = styled(Typography)`
    color: #FFFFFF;
    font-family: poppins-semibold;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    @media (min-width: 900px) and (max-width: 1150px){
        font-size: 10px;
    }
`
const BoxStyle = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const ButtonStyle = styled(Button)`
    background-color: #AC412A;
    color: #fff;
    text-align: center;
    max-width: 100%;
    min-width: 100%;
    padding: 10px 0px;
    &:hover{
        background-color: #AC412A;
    }
`
const CardDetails = styled(Typography)`
    color: #FFFFFF;
    font-family: GothicR;
    text-align: center;
    @media (max-width: 600px){
        text-align: left;
    }
`
const DetailBox = styled(Box)`
    @media (max-width: 450px){
        display: none;
    }
`
const WallOfFameCards = (props) => {

    const{image, id, address, buttontag, onItemClick, investment, roi, winrate, totaltrades, returnamount, position, onFavoriteClick, favorite} = props;
    return(
                <CardGrid >
                    <Grid xs={12} >
                        <BoxStyle>
                            <Box sx={{display:'flex', alignItems: 'center'}}>
                            <img src={image}/>
                            <DetailBox>
                            <NameTypo variant='subtitle-1'>{id}</NameTypo>
                            <NameTypo variant='subtitle-1'>{address}</NameTypo>
                            </DetailBox>  
                            </Box>
                        <FavouriteIcon  sx={{ color: favorite ? 'yellow' : '#FFFFFF' }} onClick={onFavoriteClick} />
                        </BoxStyle>
                    </Grid>
                    <Grid container spacing={1} sx={{margin: 'auto', padding: '20px 0px'}}>
                    <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails>{investment}<br/>Investment</CardDetails>
                        </Grid>
                        <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58'}}>{roi}</span><br/>ROI</CardDetails>
                        </Grid>
                        <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold', color: '#37FF58'}}>{winrate}</span><br/>Win Rate</CardDetails>
                        </Grid>
                        <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{totaltrades}</span><br/>Total Trades</CardDetails>
                        </Grid>
                        <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{returnamount}</span><br/>Return</CardDetails>
                        </Grid>
                        <Grid xs={6} sm={6} md={4} p={1}>
                            <CardDetails><span style={{ fontFamily: 'poppins-semibold' }}>{position}</span><br/>Position</CardDetails>
                        </Grid>
                    </Grid>
                    <Grid xs={12}>
                    <Link to={`/communityTradersMain/${id}`} style={{textDecoration: 'none', color: 'black'}}>
                        <ButtonStyle variant='contained' onClick={onItemClick}>
                            {buttontag}   
                        </ButtonStyle>
                    </Link>
                    </Grid>
            </CardGrid>
    )
}
export default WallOfFameCards;
